
































import { defineComponent } from '@nuxtjs/composition-api';
import { SfTopBar, SfIcon, SfLink } from '@storefront-ui/vue';
import { useTopBar } from './useTopBar';
import TopBarLinks from "~/components/TopBar/TopBarLinks.vue";

export default defineComponent({
  components: {
    SfIcon,
    SfLink,
    TopBarLinks,
    CurrencyDropDown: () => import('~/components/CurrencyDropDown/CurrencyDropDown.vue'),
    StoreSwitcherDropdown: () => import('~/components/StoreSwitcherDropdown/StoreSwitcherDropdown.vue'),
    SfTopBar
  },
  setup() {
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
    };
  },
  data() {
    return {
      gomageWebSite: {
        title: 'Visit GoMage Website ',
        src: 'https://www.gomage.com'
      }
    }
  }
});

