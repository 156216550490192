/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = `
  query storeConfig {
    storeConfig {
        store_code,
        default_title,
        store_name,
        default_display_currency_code,
        locale,
        base_media_url,
        secure_base_media_url,
        header_logo_src,
        logo_width,
        logo_height,
        logo_alt,
        base_url,
        category_url_suffix
        product_url_suffix
    }
  }
`;

export default StoreConfigQuery;
