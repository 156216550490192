














import { defineComponent } from '@nuxtjs/composition-api';
import {SfLink} from '@storefront-ui/vue';

export default defineComponent({
  name: 'TopBarLinks',
  components: {
    SfLink,
  },
  data() {
    return {
      headerLinks: [
        {
          title: 'Help Info',
          src: '/help'
        },
        {
          title: 'Contacts',
          src: '/contact'
        },
        {
          title: 'Shipping & Payment',
          src: '/shipping-and-payment'
        }
      ]
    };
  },

});

